import {
	ChangeWorkOrderQuantityProducedMutation,
	ChangeWorkOrderStatusMutation,
	CountReasonsQuery,
	CountMaterialItemMutation,
	MoveMaterialItemMutation,
	api as generatedApi,
	ItemsQuery,
	JobQuery,
	JobsQuery,
	LocationQuery,
	LocationsQuery,
	MaterialItemsQuery,
	MaterialItemLocationQuantitiesQuery,
	MaterialItemSiteLocationQuantitiesQuery,
	ReceiveVendorPurchaseOrderItemsMutation,
	SetWorkOrderLineNumberMutation,
	VendorPurchaseOrdersQuery,
	VendorPurchaseOrderByIdQuery,
	WorkOrdersQuery,
} from "~/services/graphql";

export const apiEndpoints = generatedApi.enhanceEndpoints({
	addTagTypes: ["CountReasons", "Items", "Location", "Job", "MaterialItems", "MaterialItemLocationQuantities", "VendorPurchaseOrders", "WorkOrder"],
	endpoints: {
		changeWorkOrderQuantityProduced: {
			invalidatesTags: ["WorkOrder"],
			transformResponse: (res: ChangeWorkOrderQuantityProducedMutation) => {
				return res;
			},
		},
		changeWorkOrderStatus: {
			invalidatesTags: ["WorkOrder"],
			transformResponse: (res: ChangeWorkOrderStatusMutation) => {
				return res;
			},
		},
		countMaterialItem: {
			invalidatesTags: ["MaterialItems", "MaterialItemLocationQuantities"],
			transformResponse: (res: CountMaterialItemMutation) => {
				return res;
			},
		},
		countReasons: {
			providesTags: ["CountReasons"],
			transformResponse: (res: CountReasonsQuery) => {
				return res;
			},
		},
		items: {
			providesTags: ["Items"],
			transformResponse: (res: ItemsQuery) => {
				return res;
			},
		},
		job: {
			providesTags: ["Job"],
			transformResponse: (res: JobQuery) => {
				return res;
			},
		},
		jobs: {
			providesTags: ["Job"],
			transformResponse: (res: JobsQuery) => {
				return res;
			},
		},
		location: {
			providesTags: ["Location"],
			transformResponse: (res: LocationQuery) => {
				return res;
			},
		},
		locations: {
			providesTags: ["Location"],
			transformResponse: (res: LocationsQuery) => {
				return res;
			},
		},
		materialItemLocationQuantities: {
			providesTags: ["MaterialItemLocationQuantities"],
			transformResponse: (res: MaterialItemLocationQuantitiesQuery) => {
				return res;
			},
		},
		materialItems: {
			providesTags: ["MaterialItems"],
			transformResponse: (res: MaterialItemsQuery) => {
				return res;
			},
		},
		materialItemSiteLocationQuantities: {
			providesTags: ["MaterialItemLocationQuantities"],
			transformResponse: (res: MaterialItemSiteLocationQuantitiesQuery) => {
				return res;
			},
		},
		moveMaterialItem: {
			invalidatesTags: ["MaterialItemLocationQuantities"],
			transformResponse: (res: MoveMaterialItemMutation) => {
				return res;
			},
		},
		receiveVendorPurchaseOrderItems: {
			invalidatesTags: ["VendorPurchaseOrders"],
			transformResponse: (res: ReceiveVendorPurchaseOrderItemsMutation) => {
				return res;
			},
		},
		setWorkOrderLineNumber: {
			invalidatesTags: ["WorkOrder"],
			transformResponse: (res: SetWorkOrderLineNumberMutation) => {
				return res;
			},
		},
		vendorPurchaseOrderById: {
			providesTags: ["VendorPurchaseOrders"],
			transformResponse: (res: VendorPurchaseOrderByIdQuery) => {
				return res;
			},
		},
		vendorPurchaseOrders: {
			providesTags: ["VendorPurchaseOrders"],
			transformResponse: (res: VendorPurchaseOrdersQuery) => {
				return res;
			},
		},
		workOrders: {
			providesTags: ["WorkOrder"],
			transformResponse: (res: WorkOrdersQuery) => {
				return res;
			},
		},
	},
});

export const {
	useChangeWorkOrderQuantityProducedMutation,
	useChangeWorkOrderStatusMutation,
	useCountMaterialItemMutation,
	useCountReasonsQuery,
	useItemsQuery,
	useJobQuery,
	useJobsQuery,
	useLazyJobQuery,
	useLazyJobsQuery,
	useLazyLocationsQuery,
	useLazyMaterialItemSiteLocationQuantitiesQuery,
	useLazyMaterialItemsQuery,
	useLocationQuery,
	useLocationsQuery,
	useMaterialItemSiteLocationQuantitiesQuery,
	useMaterialItemsQuery,
	useMoveMaterialItemMutation,
	useReceiveVendorPurchaseOrderItemsMutation,
	useVendorPurchaseOrderByIdQuery,
	useVendorPurchaseOrdersQuery,
	useWorkOrdersQuery,
} = apiEndpoints;
