import { Alert, Button, Grid2 as Grid } from "@mui/material";
import { useContext, useEffect, useRef } from "react";

import { AuthContext } from "~/auth";
import ItemCard from "~/components/ItemCard";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import { useSnacks } from "~/providers/SnackProvider";
import {
	MaterialItemDocument,
	MaterialItemsQueryVariables,
	ReceiveVendorPurchaseOrderItemsMutationVariables,
	useMaterialItemsQuery,
	useReceiveVendorPurchaseOrderItemsMutation,
} from "~/services/graphql";
import { closeVendorOrderEdit, resetVendorOrderReceiveQuantities } from "~/store/material/vendorOrderSlice";

import VendorOrder from "./VendorOrder";
import VendorOrderReceiveLine from "./VendorOrderReceiveLine";

const VendorOrderReceive = () => {
	const countRef = useRef<HTMLInputElement>(null);
	const dispatch = useAppDispatch();

	const authContext = useContext(AuthContext);
	const [openSnack] = useSnacks();
	const [receiveVendorPurchaseOrderItems] = useReceiveVendorPurchaseOrderItemsMutation();

	const siteLocationId = useAppSelector((state) => state.site.location?.id);
	const vendorPurchaseOrder = useAppSelector((state) => state.material.vendorOrder.vendorPurchaseOrder);
	const vendorOrderReceiveQuantities = useAppSelector((state) => state.material.vendorOrder.vendorOrderReceiveQuantities);

	useEffect(() => {
		dispatch(resetVendorOrderReceiveQuantities());
	}, []);

	useEffect(() => {
		setTimeout(() => {
			countRef.current?.focus();
		}, 100);
	}, [countRef.current]);

	const handleSubmit = async () => {
		//initial checks
		const errors = [];

		if (vendorOrderReceiveQuantities.length === 0) {
			errors.push("No items to receive");
		}

		if (!siteLocationId) {
			errors.push("Site not found");
		}

		if (errors.length > 0) {
			openSnack(errors.join(", "), "warning");
			return;
		}

		const receiveVendorPurchaseOrderItemsVariables: ReceiveVendorPurchaseOrderItemsMutationVariables = {
			input: {
				items: vendorOrderReceiveQuantities.map((q) => {
					return {
						id: q.id,
						quantityReceived: q.receivedQuantity,
						receiveToLocationId: siteLocationId || "",
					};
				}),
				receivedByUserEmail: authContext.currentUser?.email || "",
				shipmentReceivedDate: new Date().toISOString(),
				tenantId: authContext.company?.abbreviation || "",
				vendorPurchaseOrderId: vendorPurchaseOrder?.id || "",
			},
		};
		try {
			const result = await receiveVendorPurchaseOrderItems(receiveVendorPurchaseOrderItemsVariables);
			if (result.error || !result.data || result.data.receiveVendorPurchaseOrderItems?.errors) {
				openSnack("Error posting received quantities", "error");
				return;
			}
		} catch (error) {
			console.error("Error posting move", error);
			openSnack("Error posting received quantities", "error");
			return;
		}
		dispatch(closeVendorOrderEdit());
	};

	const handleCancel = () => {
		dispatch(closeVendorOrderEdit());
	};

	const orderLines = vendorPurchaseOrder?.items || [];

	const materialItemsVariables: MaterialItemsQueryVariables = {
		first: 1000, // Adjust number of items fetched as needed
		tenantId: authContext.company?.abbreviation || "NOTFOUND",
		where: {
			id: { in: (orderLines || []).map((item) => item.materialItemId) },
		},
	};

	const {
		data: materialItemsQueryData,
		error: materialItemsError,
		isLoading: materialItemsLoading,
	} = useMaterialItemsQuery(materialItemsVariables, { skip: orderLines.length === 0 });

	if (materialItemsLoading) return <div>Loading vendor purchase order</div>;

	if (materialItemsError) return <div>Error: Getting vendor purchase order</div>;

	const materialItems: MaterialItemDocument[] = materialItemsQueryData?.materialItems?.nodes || [];

	const ol = [...orderLines].sort((a, b) => {
		if (a.quantityOrdered - a.quantityReceived <= 0 && b.quantityOrdered - b.quantityReceived > 0) {
			return 1; // Move a to the end
		} else if (a.quantityOrdered - a.quantityReceived > 0 && b.quantityOrdered - b.quantityReceived <= 0) {
			return -1; // Keep b at the end
		}
		return 0; // Maintain original order for items with quantity != 0
	});
	// reducet the sum of  a.quantityOrdered - a.quantityReceived for orderLines
	const totalRemaining = ol.reduce((acc, item) => acc + item.quantityOrdered - item.quantityReceived, 0);

	return (
		<SpacedGridContainer>
			<VendorOrder />
			{orderLines.length === 0 ? (
				<SpacedGridItem maxCols={1} smMargin>
					<Alert severity="info">No order lines found</Alert>
				</SpacedGridItem>
			) : (
				<>
					{totalRemaining < 1 && (
						<SpacedGridItem maxCols={1} smMargin>
							<Alert variant="filled">All Items Received</Alert>
						</SpacedGridItem>
					)}

					{ol.map((item, i) => {
						return (
							<SpacedGridItem key={i} maxCols={2} smMargin>
								<ItemCard key={i}>
									<VendorOrderReceiveLine
										materialItem={materialItems.find((materialItem) => materialItem.id === item.materialItemId) || null}
										orderLine={item}
									/>
								</ItemCard>
							</SpacedGridItem>
						);
					})}
				</>
			)}

			<SpacedGridItem maxCols={1} smMargin>
				<Grid alignContent="right" alignItems="right" container direction="row" spacing={1}>
					<Grid size="grow">
						{totalRemaining > 0 ? (
							<Button fullWidth onClick={() => handleSubmit()} variant="contained">
								Submit
							</Button>
						) : null}
					</Grid>

					<Grid>
						<Button color="inherit" fullWidth onClick={handleCancel} variant="contained">
							{totalRemaining ? "Cancel" : "Close"}
						</Button>
					</Grid>
				</Grid>
			</SpacedGridItem>
		</SpacedGridContainer>
	);
};

export default VendorOrderReceive;
