import { Collapse, Divider, List, ListItemButton, ListItemIcon, ListItemText, useMediaQuery, Paper, PaperProps } from "@mui/material";
import Icon from "@mui/material/Icon";
import { styled } from "@mui/system";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Config from "~/config";
import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import { featurePageMapping, systemPageMapping } from "~/locationMaps";
import { toggleDrawer } from "~/store/layoutSlice";
import { updateSite } from "~/store/siteSlice";

const drawerWidth = Config.navDrawerWidth;

function NavMenu() {
	const dispatch = useAppDispatch();
	//location
	const location = useLocation();
	const initialOpenIndex = Object.keys(featurePageMapping).find((key) => {
		return new RegExp(key).test(location.pathname);
	});

	const drawerOpen = useAppSelector((state) => state.layout.drawerOpen);

	const [openIndex, setOpenIndex] = useState<string | null>(initialOpenIndex || null); // Track which item is open

	const [fullscreen, setFullscreen] = useState(false);
	const isWideScreen = useMediaQuery("(min-width:" + Config.navDrawerCoverWidth + "px)");
	const currentSite = useAppSelector((state) => state.site.location);

	const MainNavMenu = styled(Paper)<PaperProps>(({ theme }) => ({
		"& .Mui-selected": {
			color: theme.palette.mode === "dark" ? theme.palette.secondary.main : theme.palette.primary.main,
		},
		"& a": {
			color: "inherit",
			textDecoration: "none",
		},
		backgroundColor: theme.palette.selectCard.main,
		borderRadius: 0,
		flexDirection: "column",
		flexShrink: 0,
		height: "100%",
		position: isWideScreen ? "unset" : "fixed",
		transition: "margin-left 0.3s ease",
		width: isWideScreen ? drawerWidth : "100%",
		zIndex: 1,
	}));

	const MemoizedMainNavMenu = useMemo(() => MainNavMenu, [isWideScreen]);

	const drawerClose = () => {
		!isWideScreen && dispatch(toggleDrawer());
	};

	const handleToggle = (index: string) => {
		// Toggle the collapse for the clicked item
		if (openIndex === index) {
			setOpenIndex(null);
		} else {
			setOpenIndex(index);
		}
		//setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
	};

	const isActiveLink = (path: string) => location.pathname === path; // Check if link is active

	useEffect(() => {
		const doc = window.document;
		const docEl = doc.documentElement;

		const requestFullScreen = docEl.requestFullscreen;
		const cancelFullScreen = doc.exitFullscreen;

		if (fullscreen) {
			// Check if fullscreen is supported and not already active
			if (requestFullScreen && !document.fullscreenElement) {
				requestFullScreen.call(docEl);
			}
		} else {
			// Check if document is in fullscreen before calling exitFullscreen
			if (cancelFullScreen && document.fullscreenElement) {
				cancelFullScreen.call(doc);
			}
		}
	}, [fullscreen]);

	return (
		<MemoizedMainNavMenu
			style={{
				marginLeft: drawerOpen ? 0 : isWideScreen ? drawerWidth * -1 : "-900px",
			}}
		>
			<List>
				{Object.keys(featurePageMapping).map(function (page) {
					if (featurePageMapping[page].children) {
						const children = featurePageMapping[page].children;
						return (
							<Fragment key={page}>
								<ListItemButton onClick={() => handleToggle(page)}>
									<ListItemIcon>
										<Icon>{featurePageMapping[page].icon}</Icon>
									</ListItemIcon>
									<ListItemText primary={featurePageMapping[page].label} />
									{openIndex === page ? <Icon>keyboard_arrow_up</Icon> : <Icon>keyboard_arrow_down</Icon>}
								</ListItemButton>
								<Collapse in={openIndex === page} timeout="auto" unmountOnExit>
									{Object.keys(children).map(function (childpage) {
										if (!children[childpage].nav) return null;
										return (
											<Link key={childpage} onClick={() => drawerClose()} to={childpage}>
												<ListItemButton selected={isActiveLink(childpage)}>
													<ListItemIcon>
														<Icon>{children[childpage].icon}</Icon>
													</ListItemIcon>
													<ListItemText primary={children[childpage].label} />
												</ListItemButton>
											</Link>
										);
									})}
								</Collapse>
							</Fragment>
						);
					} else {
						if (!featurePageMapping[page].nav) return null;
						return (
							<Link key={page} onClick={() => drawerClose()} to={page}>
								<ListItemButton selected={isActiveLink(page)}>
									<ListItemIcon>
										<Icon>{featurePageMapping[page].icon}</Icon>
									</ListItemIcon>

									<ListItemText primary={featurePageMapping[page].label} />
								</ListItemButton>
							</Link>
						);
					}
				})}
			</List>
			<Divider />
			<List>
				{Object.keys(systemPageMapping).map(function (page) {
					if (!systemPageMapping[page].nav) return null;
					return (
						<Link key={page} onClick={() => drawerClose()} to={page}>
							<ListItemButton key={page} selected={isActiveLink(page)}>
								<ListItemIcon>
									<Icon>{systemPageMapping[page].icon}</Icon>
								</ListItemIcon>
								<ListItemText primary={systemPageMapping[page].label} />
							</ListItemButton>
						</Link>
					);
				})}
				<ListItemButton
					key="site"
					onClick={() => {
						dispatch(updateSite(null));
						drawerClose();
					}}
				>
					<ListItemIcon>
						<Icon>location_on</Icon>
					</ListItemIcon>
					<ListItemText primary={currentSite ? currentSite.name : "Select Site"} />
				</ListItemButton>
				<ListItemButton
					key="fullscreeen"
					onClick={() => {
						setFullscreen(!fullscreen);
						drawerClose();
					}}
				>
					<ListItemIcon>
						<Icon>{fullscreen ? "fullscreen_exit" : "fullscreen"}</Icon>
					</ListItemIcon>
					<ListItemText primary={fullscreen ? "Exit full screen" : "Full screen"} />
				</ListItemButton>
			</List>
		</MemoizedMainNavMenu>
	);
}

export default NavMenu;
