import { useContext } from "react";

import { AuthContext } from "~/auth";
import { useLocationQuery } from "~/services/graphql";
interface LocationProps {
	locationId: string;
}

const LocationName = ({ locationId }: LocationProps) => {
	const authContext = useContext(AuthContext);

	const { data, error, isLoading } = useLocationQuery({
		locationId: locationId,
		tenantId: authContext.company?.abbreviation || "UNKNOWN",
	});

	if (isLoading) return <div>Loading material items...</div>;
	if (error) return <div>Error: Getting material items</div>;

	return <>{data && data.location ? <>{data.location.name}</> : <>Location not found</>}</>;
};

export default LocationName;
