import { Grid2 as Grid, Typography } from "@mui/material";
import { GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid";
import { Fragment } from "react";

import HeaderCard from "~/components/HeaderCard";
import SpacedGridItem from "~/components/SpacedGridItem";
import { stringFormatDateTime, checkValidDateISO } from "~/helpers/timestampFormat";
import { useAppSelector } from "~/hooks/reduxHooks";
import { VendorPurchaseOrderDocument } from "~/services/graphql";

const columns: GridColDef[] = [
	{ field: "id", flex: 1, headerName: "id" },
	{ field: "vendorName", flex: 1, headerName: "Vendor" },
	{ field: "erpId", flex: 1, headerName: "ERP ID" },
	{ field: "orderPlacedDate", flex: 1, headerName: "Order Placed" },
	{ field: "purchaseOrderNumber", flex: 1, headerName: "PO Number" },
	{ field: "status", flex: 1, headerName: "Status" },
	{ field: "created", flex: 1, headerName: "Created" },
	{ field: "updated", flex: 1, headerName: "Updated" },
	{ field: "siteLocationId", flex: 1, headerName: "Site Location ID" },
	{ field: "tenantId", flex: 1, headerName: "Tenant ID" },
];

const columnVisibilityModel: GridColumnVisibilityModel = {
	created: false,
	erpId: true,
	id: false,
	orderPlacedDate: true,
	purchaseOrderNumber: true,
	siteLocationId: false,
	status: true,
	tenantId: false,
	updated: false,
};

const formatter = (val: string) => {
	// if val matches the format of toISOString, return a formatted date string
	if (checkValidDateISO(val)) {
		return stringFormatDateTime(val);
	}
	return val;
};

const VendorOrder = () => {
	const vendorPurchaseOrder = useAppSelector((state) => state.material.vendorOrder.vendorPurchaseOrder);

	if (!vendorPurchaseOrder) return null;
	return (
		<>
			<SpacedGridItem maxCols={1} smMargin>
				<HeaderCard>
					{columns.map((col, j) => {
						if (columnVisibilityModel && columnVisibilityModel[col.field] === false) return null;
						return (
							<Fragment key={j}>
								<Grid size={4} style={{ textAlign: "right" }}>
									<Typography color="textSecondary" component="span" variant="inherit">
										{col.headerName} :
									</Typography>
								</Grid>
								<Grid size={8}>{formatter(String(vendorPurchaseOrder[col.field as keyof VendorPurchaseOrderDocument]) || "")}</Grid>
							</Fragment>
						);
					})}
				</HeaderCard>
			</SpacedGridItem>
		</>
	);
};

export default VendorOrder;
